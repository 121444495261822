import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  GET_PAYMENT_INFO,
  GET_PAYMENT_INFOVariables,
  GET_ACCOUNT_INVOICES_invoices_items,
} from './graphql/GET_PAYMENT_INFO';
import {
  GET_LAST_DAY_TO_PAY,
  GET_LAST_DAY_TO_PAYVariables,
} from './graphql/GET_LAST_DAY_TO_PAY';
import { formatDate, formatMoney } from '../../util/formatters';
import useMultipleFeatureFlags from '../../util/hooks/useMultipleFeatureFlags';
// eslint-disable-next-line import/no-cycle
import NextPayment, { PaymentInformation } from './NextPayment';

const getPaymentInfoQuery = loader(
  './graphql/queries/Get_Payment_Info.graphql',
);

const getMigratedStatus = loader(
  './graphql/queries/Has_Migrated_Policy.graphql',
);

const getLastDayToPayQuery = loader(
  './graphql/queries/Get_Last_Day_To_Pay.graphql',
);

interface NextPaymentDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  sourceSystemCode: string;
}

export interface LastDayToPayInfoProps {
  showLastDayToPay: boolean;
  pastDueAmount: string | null;
  date: Date | string | null;
}

interface GetMigratedStatusProps {
  hasMigratedPolicy: {
    hasMigratedPolicy: boolean;
  };
}

export function getMigratedPolicyData(
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<GetMigratedStatusProps>> {
  const variables: GET_PAYMENT_INFOVariables = {
    account_number: accountNumber,
  } as GET_PAYMENT_INFOVariables;
  return awsAppSyncClient.query({
    query: getMigratedStatus,
    variables,
  }) as Promise<ApolloQueryResult<GetMigratedStatusProps>>;
}

export function fetchPaymentInfo(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_PAYMENT_INFO>> {
  const variables: GET_PAYMENT_INFOVariables = {
    account_number: accountNumber,
  } as GET_PAYMENT_INFOVariables;

  const queryResult: Promise<ApolloQueryResult<GET_PAYMENT_INFO>> =
    awsAppSyncClient.query({
      query: getPaymentInfoQuery,
      variables,
    }) as Promise<ApolloQueryResult<GET_PAYMENT_INFO>>;
  return queryResult;
}

export function fetchLastDayToPayInfo(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_LAST_DAY_TO_PAY>> {
  const variables: GET_LAST_DAY_TO_PAYVariables = {
    account_number: accountNumber,
  } as GET_LAST_DAY_TO_PAYVariables;

  const queryResult: Promise<ApolloQueryResult<GET_LAST_DAY_TO_PAY>> =
    awsAppSyncClient.query({
      query: getLastDayToPayQuery,
      variables,
    }) as Promise<ApolloQueryResult<GET_LAST_DAY_TO_PAY>>;
  return queryResult;
}

export const processShowDisclaimer = (
  flagDetails: any,
  oneIncDisclaimer: boolean,
): boolean => {
  let showDisclaimer: boolean = false;

  if (flagDetails) {
    const PLCCFeeDisclaimerFeatureFlagIndex = flagDetails.findIndex(
      (flag: any) => {
        return flag.flagName === 'PLCCFeeDisclaimer';
      },
    );
    const NoDisclaimerFeeFeatureFlagIndex = flagDetails.findIndex(
      (flag: any) => {
        return flag.flagName === 'DisablePLCCFeeDisclaimerCheck';
      },
    );

    if (flagDetails[NoDisclaimerFeeFeatureFlagIndex]) {
      if (flagDetails[NoDisclaimerFeeFeatureFlagIndex].flagDetails) {
        if (flagDetails[NoDisclaimerFeeFeatureFlagIndex].flagDetails.enabled) {
          showDisclaimer = true;
        }
      }
    }
    if (flagDetails[PLCCFeeDisclaimerFeatureFlagIndex] && !showDisclaimer) {
      if (flagDetails[PLCCFeeDisclaimerFeatureFlagIndex].flagDetails) {
        if (
          flagDetails[PLCCFeeDisclaimerFeatureFlagIndex].flagDetails.enabled
        ) {
          showDisclaimer = oneIncDisclaimer ?? false;
        }
      }
    }
  }

  return showDisclaimer;
};

export const processResponsiveIndicator = (
  flagDetails: any,
  responsiveBillIndicator: string | null | undefined,
) => {
  let responsiveIndicator: string = 'yes';

  const NextPaymentAutoDraftIndicatorFFIndex = flagDetails.findIndex(
    (flag: any) => {
      return flag.flagName === 'NextPaymentAutoDraftIndicator';
    },
  );

  if (flagDetails[NextPaymentAutoDraftIndicatorFFIndex]) {
    if (flagDetails[NextPaymentAutoDraftIndicatorFFIndex].flagDetails) {
      if (
        flagDetails[NextPaymentAutoDraftIndicatorFFIndex].flagDetails.enabled
      ) {
        if (responsiveBillIndicator) {
          responsiveIndicator = responsiveBillIndicator;
        }
      }
    }
  }

  return responsiveIndicator;
};

export const processFeatureFlags = (
  responsiveBillIndicator: string | null | undefined,
  oneIncDisclaimer: boolean | null | undefined,
  featureFlagDetails: any,
) => {
  let showDisclaimer: boolean = false;
  let responsiveIndicator: string = 'no';

  if (oneIncDisclaimer) {
    showDisclaimer = processShowDisclaimer(
      featureFlagDetails,
      oneIncDisclaimer,
    );
  }

  responsiveIndicator = processResponsiveIndicator(
    featureFlagDetails,
    responsiveBillIndicator,
  );
  return {
    responsiveIndicator,
    showDisclaimer,
  };
};

const formatAmountDue = (amountDue: string | null | undefined) => {
  if (!amountDue) {
    return '-';
  }

  if (amountDue.toUpperCase() === 'PAID IN FULL') {
    return 'PAID IN FULL';
  }

  return formatMoney(amountDue);
};

export const showDisclaimerPLModal = (data: GET_PAYMENT_INFO) => {
  let showMigratedModal = false;
  if (
    data?.account?.items &&
    data.account.items[0] &&
    data.account.items[0].policies &&
    data.account.items[0].policies.items
  ) {
    for (
      let i = 0;
      i <= data?.account?.items[0]?.policies?.items?.length - 1;
      i += 1
    ) {
      if (data?.account?.items[0]?.policies?.items[i]?.migrated) {
        if (data.account.items[0]?.policies?.items[i]?.migrated === 'Y') {
          showMigratedModal = true;
        }
      }
    }
  }
  return showMigratedModal;
};

const NextPaymentDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  sourceSystemCode,
}: NextPaymentDataContainerProps) => {
  const [paymentInfo, setPaymentInfo] = useState<PaymentInformation>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showPastDue, setShowPastDue] = useState<boolean>(false);
  const [isMigrated, setIsMigrated] = useState<boolean>(false);
  const [onlyMortgageeBilledInvoices, setOnlyMortgageeBilledInvoices] =
    useState<boolean>(true);
  const [mortgageeInvoice, setMortgageeInvoice] =
    useState<GET_ACCOUNT_INVOICES_invoices_items | null>(null);
  const [
    reRenderComponentAfterChangedDueDate,
    setReRenderComponentAfterChangedDueDate,
  ] = useState<boolean>(false);
  const [pastDueAmount, setPastDueAmount] = useState<string>('');
  const [lastDayToPayInfo, setLastDayToPayInfo] =
    useState<LastDayToPayInfoProps>({
      showLastDayToPay: false,
      pastDueAmount: null,
      date: null,
    });

  const featureFlagDetails = useMultipleFeatureFlags([
    'NextPaymentAutoDraftIndicator',
    'PLCCFeeDisclaimer',
    'DisablePLCCFeeDisclaimerCheck',
  ]);

  useEffect(() => {
    fetchPaymentInfo(awsAppSyncClient, accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_PAYMENT_INFO>) => {
        if (apolloQueryResult.data.invoices?.items) {
          setMortgageeInvoice(
            apolloQueryResult.data.invoices?.items[0] ?? null,
          );
        }
        if (apolloQueryResult.data.account?.items) {
          setPastDueAmount(
            apolloQueryResult?.data?.account?.items[0]
              ?.next_payment_no_mortgagee_billed?.pastDueAmount ?? '',
          );
          const payment =
            apolloQueryResult.data.account?.items[0]
              ?.next_payment_no_mortgagee_billed;
          const processedFeatureFlagIndicators = processFeatureFlags(
            payment?.responsiveBillIndicator,
            apolloQueryResult.data.account?.items[0]?.one_inc_disclaimer,
            featureFlagDetails,
          );

          setPaymentInfo({
            paymentAmount: formatAmountDue(payment?.paymentAmount) ?? '$0.00',
            paymentDueDate: formatDate(payment?.paymentDueDate) ?? '-',
            pastDueAmount: formatMoney(payment?.pastDueAmount) ?? '$0.00',
            defaultBillingMethod: payment?.defaultBillingMethod ?? '-',
            showDisclaimer: processedFeatureFlagIndicators.showDisclaimer,
            responsiveIndicator:
              processedFeatureFlagIndicators.responsiveIndicator,
            lastPaymentAmount:
              formatMoney(payment?.lastPaymentAmount) ?? '$0.00',
            lastPaymentDate: formatDate(payment?.lastPaymentDate) ?? '-',
          });

          if (!!payment?.mortgageeBilledOnly === false) {
            setOnlyMortgageeBilledInvoices(false);
          }

          if (
            payment?.pastDueAmount !== '$0.00' &&
            payment?.pastDueAmount !== '0'
          ) {
            setShowPastDue(true);
          }
        }

        setLoading(false);
      })
      .catch((err: Error) => {
        console.error('GET_PAYMENT_INFO ERROR: ', err);
        setError(err);
        setLoading(false);
      });
    fetchLastDayToPayInfo(awsAppSyncClient, accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_LAST_DAY_TO_PAY>) => {
        if (
          apolloQueryResult?.data?.account?.items[0]?.lastDayToPay
            ?.hasPastDue &&
          apolloQueryResult?.data?.account?.items[0]?.lastDayToPay?.policy
            ?.status === 'CANCELING'
        ) {
          const date = new Date(
            apolloQueryResult?.data?.account?.items[0]?.lastDayToPay?.policy
              ?.trans_effective_date ?? new Date(),
          );
          date.setDate(date.getDate() - 1);

          setLastDayToPayInfo({
            showLastDayToPay: true,
            pastDueAmount,
            date,
          });
        }
      })
      .catch((err: Error) => {
        console.error('GET_LAST_DAY_TO_PAY ERROR: ', err);
      });
  }, [
    accountNumber,
    featureFlagDetails[0].flagDetails,
    featureFlagDetails[1].flagDetails,
    featureFlagDetails[2].flagDetails,
    paymentInfo?.paymentDueDate,
    reRenderComponentAfterChangedDueDate,
  ]);

  useEffect(() => {
    getMigratedPolicyData(accountNumber, awsAppSyncClient)
      .then((apolloQueryResult: ApolloQueryResult<GetMigratedStatusProps>) => {
        if (
          apolloQueryResult.data &&
          apolloQueryResult.data?.hasMigratedPolicy &&
          apolloQueryResult.data?.hasMigratedPolicy?.hasMigratedPolicy
        ) {
          setIsMigrated(
            apolloQueryResult.data.hasMigratedPolicy.hasMigratedPolicy,
          );
        }
      })
      .catch((err: Error) => {
        console.error('GET POLICY MIGRATED STATUS ERROR: ', err);
      });
  }, [accountNumber]);

  return (
    <>
      <NextPayment
        accountNumber={accountNumber}
        paymentInfo={paymentInfo}
        showPastDue={showPastDue}
        awsAppSyncClient={awsAppSyncClient}
        loading={loading}
        error={error}
        onlyMortgageeBilledInvoices={onlyMortgageeBilledInvoices}
        mortgageeInvoice={mortgageeInvoice}
        setReRenderComponentAfterChangedDueDate={
          setReRenderComponentAfterChangedDueDate
        }
        sourceSystemCode={sourceSystemCode}
        lastDayToPayInfo={lastDayToPayInfo}
        isMigrated={isMigrated}
      />
    </>
  );
};

export default NextPaymentDataContainer;
